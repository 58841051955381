import React from "react"
import useMyPosts from "../hooks/useMyPosts"
import { Spin } from "antd"
import { CommunityPost } from "./CommunityPost"
import { Moon } from "../../../../assets/images"
import { LoadingOutlined } from "@ant-design/icons"
import { CommunityPostDetail } from "./CommunityPostDetail"
import { ICommunityPost } from "../types/commonn"
import { useSearchParams } from "react-router-dom"

const MyPostsList = () => {
  const { lastCardElementRef, myPosts, myPostsQuery } = useMyPosts()
  const [searchParams, setUrlSearchParams] = useSearchParams()
  const [selectedPost, setSelectedPost] = React.useState<ICommunityPost | null>(
    null,
  )

  const showPostShowDetails = (post: ICommunityPost) => {
    setSelectedPost(post)
    setUrlSearchParams({ post: post.id })
  }

  const removePostShowDetails = () => {
    setSelectedPost(null)

    if (searchParams) {
      searchParams.delete("post")
      setUrlSearchParams({})
    }
  }

  return (
    <div className="mt-6">
      {selectedPost === null ? (
        <>
          {myPosts?.length === 0 && myPostsQuery.isLoading ? (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : myPosts?.length > 0 ? (
            <Spin
              spinning={myPostsQuery.isLoading}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
            >
              <div className="mt-4 grid gap-4">
                {myPosts?.map((post) => (
                  <CommunityPost
                    className="bg-white"
                    key={post.id}
                    post={post}
                    onClick={() => showPostShowDetails(post)}
                    observ={lastCardElementRef}
                  />
                ))}
              </div>
            </Spin>
          ) : (
            <Spin
              spinning={myPostsQuery.isLoading}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
            >
              <div className="flex gap-3 items-center bg-white p-3 rounded-xl shadow-md">
                <img src={Moon} alt="cookies" />
                <div>{"Nothing to display"}</div>
              </div>
            </Spin>
          )}
        </>
      ) : (
        <CommunityPostDetail
          post={selectedPost}
          removePostShowDetails={removePostShowDetails}
        />
      )}
    </div>
  )
}

export default MyPostsList
