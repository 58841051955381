import React from "react"
import { Breadcrumbs } from "../components/Breadcumbs"
import { Skeleton, Typography } from "antd"
import { useProfile } from "../../modules/missionControl/hooks/useProfile"
import { Avatar } from "antd"
import { useNavigate } from "react-router-dom"

interface PageHeaderWrapperProps {
  children: React.ReactNode
  className?: string
}

export const PageHeaderWrapper: React.FC<PageHeaderWrapperProps> = ({
  children,
  className,
}) => (
  <div className={`bg-white p-4 px-6 w-full ${className ?? ""}`}>
    {children}
  </div>
)

interface PageTitleProps {
  title?: string | React.ReactNode | React.ReactElement | any
  first_name?: string
  last_name?: string
}

const PageTitle = React.forwardRef<HTMLHeadingElement, PageTitleProps>(
  ({ title, first_name, last_name }, ref) => {
    if (typeof title === "string") {
      return (
        <Typography.Title ref={ref} className="!mb-1">
          {title}
        </Typography.Title>
      )
    } else if (
      typeof title === "object" &&
      (title?.type === "span" || title?.type === "div")
    ) {
      return title
    }
    return (
      <Typography.Title
        ref={ref}
        className="text-2xl my-1 !mb-1"
      >{`Hello, ${first_name} ${last_name}!`}</Typography.Title>
    )
  },
)

interface PageHeaderProps {
  breadcrumbItems?: any
  title?: string | React.ReactNode
  className?: string
  isLoading?: boolean
  isError?: boolean
  subtitle?: string | React.ReactNode
  isHeaderForAccountInfo?: boolean
  children?: React.ReactNode | React.ReactElement
  buttonComponent?: React.ReactNode | React.ReactElement
  showBack?: boolean
  goBackTo?: string
  needButton?: boolean
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  breadcrumbItems,
  title,
  className,
  isLoading,
  isError,
  subtitle,
  isHeaderForAccountInfo,
  children,
  showBack,
  goBackTo,
  needButton,
  buttonComponent,
  ...rest
}) => {
  const { account, loadingAccount, errorAccount } = useProfile()
  const navigate = useNavigate()
  const origin = "https://media.licdn.com/"

  if (isError || (isHeaderForAccountInfo && errorAccount)) {
    return (
      <PageHeaderWrapper className={className ?? ""}>
        <div className="text-danger">
          Error. Please try again or contact us, if the problem persists.
        </div>
      </PageHeaderWrapper>
    )
  }

  if (isLoading || (isHeaderForAccountInfo && loadingAccount)) {
    return (
      <PageHeaderWrapper className={className ?? ""}>
        <Skeleton active avatar />
      </PageHeaderWrapper>
    )
  }

  if (isHeaderForAccountInfo) {
    return (
      <PageHeaderWrapper {...rest} className={className ?? ""}>
        <Breadcrumbs items={breadcrumbItems} />

        <div className="flex items-center justify-start gap-3 mt-4">
          {account?.data?.data?.profile_picture ? (
            <img
              src={
                account?.data?.data?.profile_picture?.startsWith(origin)
                  ? account?.data?.data?.profile_picture ||
                    account?.data?.data?.avatar
                  : `${
                      account?.data?.data?.profile_picture ||
                      account?.data?.data?.avatar
                    }?${new Date().getTime()}`
              }
              alt="profile"
              className="h-[58px] w-[58px] rounded-full object-contain"
              style={{ border: "1px solid #D4F5C5" }}
              onError={(e) => {
                const img = e.target as HTMLImageElement
                img.onerror = null
                img.src = account?.data?.data?.avatar
              }}
            />
          ) : (
            <Avatar
              src={account?.data?.data?.avatar}
              size={58}
              className=""
              style={{ border: "1px solid #D4F5C5" }}
            />
          )}
          <div className="flex flex-col">
            <PageTitle
              title={title}
              first_name={account?.data?.data?.first_name}
              last_name={account?.data?.data?.last_name}
            />
            <div className="text-gray-400 ">
              {subtitle
                ? subtitle
                : account?.data?.data?.profile_headline || (
                    <span
                      className="cursor-pointer hover:underline text-secondary"
                      onClick={() =>
                        navigate("/dashboard/launchpad/cv-builder")
                      }
                    >
                      Your profile tagline will appear here. Go to CV Builder to
                      edit.
                    </span>
                  )}
            </div>
          </div>
        </div>
        {children}
      </PageHeaderWrapper>
    )
  }

  return (
    <PageHeaderWrapper
      {...rest}
      className={className ? className : (rest as any)?.className}
    >
      <Breadcrumbs items={breadcrumbItems} />
      {showBack && (
        <div className="mt-3">
          <div
            onClick={() => (goBackTo ? navigate(goBackTo) : navigate(-1))}
            className="text-secondary w-fit cursor-pointer transition-transform transform hover:scale-[101%]"
          >
            {"<<"} back
          </div>
        </div>
      )}
      {title && (
        <div
          className={`mt-3 ${
            needButton ? "flex items-center justify-between" : ""
          }`}
        >
          <PageTitle title={title} />
          {subtitle && <div className="text-gray-400">{subtitle}</div>}
          {needButton && buttonComponent}
        </div>
      )}
      {children}
    </PageHeaderWrapper>
  )
}
