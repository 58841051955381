import React from "react"
import { BasicContainerWithTitle } from "../../../../common/components"
import { Link } from "react-router-dom"
import {
  TabletOutlined,
  UserOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons"

export const Shortcut = React.forwardRef<HTMLDivElement>((_, ref) => {
  const links = [
    {
      to: "/community/feed",
      text: "Feed",
      icon: <TabletOutlined className="text-xl" />,
    },
    {
      to: "/community/my",
      text: "Profile",
      icon: <UserOutlined className="text-xl" />,
    },
    {
      to: "/community/my",
      text: "Mission Control -My Launchpad",
      icon: <DashboardOutlined className="text-xl" />,
    },
    {
      to: "/dashboard/launchpad/cv-builder",
      text: "My Green CV",
      icon: <EnvironmentOutlined className="text-xl" />,
    },
  ]

  return (
    <BasicContainerWithTitle
      type="small"
      ref={ref}
      title="Shortcut"
      titleSize="h3"
      className="text-base"
    >
      <div className="flex flex-col gap-4 px-2 mt-6 items-start justify-start">
        {links.map((link, index) => (
          <Link key={index} to={link.to} className="flex items-center gap-2">
            {link.icon}{" "}
            <span className=" text-base hover:text-secondary duration-300 transition-all">
              {link.text}
            </span>
          </Link>
        ))}
      </div>
    </BasicContainerWithTitle>
  )
})
