import React from "react"
import { THowToPostBody } from "../../types/commonn"

interface HowToProps {
  body: THowToPostBody
}

const HowTo: React.FC<HowToProps> = ({ body }) => {
  return (
    <div className="">
      {/* <h1 className="text-4xl font-bold mb-8 text-gray-800">
        How-To Posts
      </h1> */}
      <div className="flex flex-wrap justify-center">
        {body?.map((howTo, index) => (
          <HowToCard key={howTo.id} step={index + 1} {...howTo} />
        ))}
      </div>
    </div>
  )
}

interface HowToCardProps {
  id: string
  step: number
  name: string
  description: string
  image_url: string
}

const HowToCard: React.FC<HowToCardProps> = ({
  step,
  name,
  description,
  image_url,
}) => {
  return (
    <div className="flex bg-white relative rounded-lg overflow-hidden shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl m-4">
      <div className="w-1/3">
        <img
          className="w-full h-full object-cover"
          src={image_url}
          alt={name}
        />
      </div>
      <div className="absolute rounded-bl-lg right-0 top-0 font-bold bg-secondary text-white text-xl  px-3 py-1">
       STEP {step}
      </div>
      <div className="w-2/3 p-6">
        <div className="flex flex-col justify-center items-start gap-2">
          <div className="font-bold text-xl mb-2 ">{name}</div>
        </div>
        <div className="text-gray-700 text-base">{description}</div>
      </div>
    </div>
  )
}
export default HowTo
