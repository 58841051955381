import React, { useState } from "react"
import { Upload, message } from "antd"
import { PictureOutlined, DeleteOutlined } from "@ant-design/icons"
import clsx from "clsx"

interface FormImageUploaderProps {
  value: any[]
  onChange: (file: any[]) => void
  className?: string
  description?: string
  style?: React.CSSProperties
  height?: number
}

const FormImageUploader: React.FC<FormImageUploaderProps> = ({
  value,
  onChange,
  className,
  description,
  style,
  height,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  return value.length > 0 && value?.[0] ? (
    <div
      className={clsx("relative", className)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ height, borderRadius: "20px" }}
    >
      <img
        src={URL.createObjectURL(value?.[0] as File)}
        alt="avatar"
        className="absolute top-0 left-0 object-contain w-full h-full"
        style={{ borderRadius: "20px" }}
      />
      <div
        className={clsx(
          "absolute inset-0 top-0 left-0 z-10 flex items-center justify-center w-full h-full gap-2 bg-black opacity-0 transtion-all duration-300",
          { "hover:opacity-50 ": isHovered },
        )}
        style={{ borderRadius: "20px" }}
      >
        <DeleteOutlined
          className="z-50 text-2xl text-white"
          onClick={(e) => {
            e.stopPropagation()
            onChange([])
          }}
        />
      </div>
    </div>
  ) : (
    <Upload.Dragger
      name="image"
      height={height}
      listType="picture"
      style={
        style ? { ...style, borderRadius: "20px" } : { borderRadius: "20px" }
      }
      className="w-1/5 rounded-lg aspect-square"
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={({ file }) => onChange([file])}
      onChange={() => {}}
      fileList={value || []}
      maxCount={1}
    >
      <PictureOutlined className="text-3xl" />
      <p className="mt-1">{description ?? "Upload"}</p>
    </Upload.Dragger>
  )
}

function dataURLtoFile(dataURL: string, fileName: string): File {
  const arr = dataURL.split(",")
  const mime = arr[0].match(/:(.*?);/)?.[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], fileName, { type: mime })
}

export function beforeUpload(file: File) {
  const allowedTypes = ["image/jpeg", "image/png"]
  const maxSize = 2 * 1024 * 1024 // 2MB
  return new Promise<File>((resolve, reject) => {
    // Check file type
    if (!allowedTypes.includes(file.type)) {
      message.error("Only JPEG and PNG files are allowed")
      reject(new Error("Invalid file type"))
      return
    }

    // Check file size
    if (file.size > maxSize) {
      message.error("File size exceeds the limit (2MB)")
      reject(new Error("File size exceeded"))
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image()
      img.src = event.target?.result as string
      img.onload = () => {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")

        const MAX_WIDTH = 800
        const MAX_HEIGHT = 800

        let width = img.width
        let height = img.height

        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }

        canvas.width = width
        canvas.height = height

        ctx?.drawImage(img, 0, 0, width, height)

        const compressedDataURL = canvas.toDataURL(file.type) // Use original image format
        const compressedFile = dataURLtoFile(compressedDataURL, file.name)

        resolve(compressedFile)
      }
    }

    reader.onerror = (error) => reject(error)
  })
}

export default FormImageUploader
