import React, { useState } from "react"
import { Item } from "../../hooks/useReferences"
import { AutoComplete, Empty, Spin } from "antd"
import { DownOutlined } from "@ant-design/icons"

interface AutoCompleteProps {
  options?: Item[]
  onSelect?: (value: string, option: Item | undefined | null) => void
  selectedValue?: Item
  emptyDescription?: string
  className?: string
  placeholder?: string
  style?: React.CSSProperties
  notFoundContent?: React.ReactNode
  fieldNames?: { label: string; value: string }
  onClearValue?: null | undefined
  disabled?: boolean
  isLoading?: boolean
}

const AutoCompleteNonInfinity: React.FC<AutoCompleteProps> = ({
  onSelect,
  options,
  selectedValue,
  emptyDescription,
  className,
  placeholder,
  style,
  notFoundContent,
  fieldNames,
  // onClearValue = undefined,
  isLoading,
  disabled,
}) => {

  const [searchParam, setSearchParam] = useState<string>("")
  React.useEffect(() => {
    setSearchParam(selectedValue?.name ?? "")
  }, [selectedValue])

  // Filter options directly based on searchParam
  const filteredOptions = options?.filter(option =>
    option.name.toLowerCase().includes(searchParam.toLowerCase())
  ) ?? []

  return (
    <AutoComplete
      disabled={disabled}
      fieldNames={fieldNames ?? { label: "name", value: "id" }}
      style={style}
      className={className ?? "!w-full cursor-pointer"}
      placeholder={placeholder ?? "Search"}
      options={filteredOptions ?? []}
      onSearch={(value) => {
        console.log('ccvalue', value)
        setSearchParam(value)
      }}
      onSelect={onSelect}
      defaultValue={selectedValue?.name || searchParam}
      value={selectedValue?.name || searchParam}
      suffixIcon={<DownOutlined />}
      notFoundContent={
        isLoading ? (
          <div className="flex items-center justify-center p-6">
            <Spin />
          </div>
        ) : (
          notFoundContent ?? (
            <Empty
              description={emptyDescription ?? "Sorry, we've got nothing"}
            />
          )
        )
      }
    />
  )
}

export default AutoCompleteNonInfinity
