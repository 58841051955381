import React from "react"
import { EnvironmentOutlined } from "@ant-design/icons"
import { FounderResponse } from "../../founder/types/response"
import AvatarCustom from "../../../../common/components/AvatarCustom"

interface FounderItemProps {
  founder?: FounderResponse
}

const FounderItem: React.FC<FounderItemProps> = ({ founder }) => {
  return (
    <div className="flex flex-col gap-2 items-center justify-center">
      <div className="flex items-center justify-center flex-col">
        <AvatarCustom
          url={founder?.avatar}
          display_name={founder?.first_name + " " + founder?.last_name}
          label={"Founder"}
        />
      </div>

      <div className="flex flex-col items-center gap-2 justify-center">
        <h3>
          {founder?.first_name} {founder?.last_name}
        </h3>
        <div className="-mt-2 mb-2 flex items-center gap-2 justify-center">
          <EnvironmentOutlined /> {founder?.country}
        </div>
      </div>
    </div>
  )
}

export default FounderItem
