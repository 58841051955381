import { message } from "antd"
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  AuthError,
} from "@azure/msal-browser"

export type TitleSize = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

export const getTitleAccordingToSize = (
  titleSize: TitleSize,
  title: string,
  titleRef?: React.RefObject<HTMLDivElement | HTMLHeadingElement>,
) => {
  switch (titleSize) {
    case "h1":
      return (
        <h1 ref={titleRef} className="first-letter:uppercase">
          {title}
        </h1>
      )
    case "h2":
      return (
        <h2 ref={titleRef} className="first-letter:uppercase">
          {title}
        </h2>
      )
    case "h3":
      return (
        <h3 ref={titleRef} className="first-letter:uppercase">
          {title}
        </h3>
      )
    case "h4":
      return (
        <h4 ref={titleRef} className="first-letter:uppercase">
          {title}
        </h4>
      )
    case "h5":
      return (
        <h5 ref={titleRef} className="first-letter:uppercase">
          {title}
        </h5>
      )
    case "h6":
      return (
        <h6 ref={titleRef} className="first-letter:uppercase">
          {title}
        </h6>
      )
    default:
      return (
        <h2 ref={titleRef} className="first-letter:uppercase">
          {title}
        </h2>
      )
  }
}

export const errorMessage = (
  messageString: string | undefined,
  error?: any,
  key: string = "error-message",
) => {
  if (
    !(
      error instanceof BrowserAuthError ||
      error instanceof InteractionRequiredAuthError ||
      error instanceof AuthError
    )
  ) {
    message.error({ content: messageString, key })
  }
}
