import { message } from "antd"
import { ApiResponse2 } from "../common/types/ApiResponse"
import instance from "../config/config"
import { useQuery } from "react-query"

export const useGreenSkillByCategory = (id: string | undefined) => {
  const handleFetchError = (error: any) => {
    message.error({
      content: `An error occurred while fetching job sector: ${error?.data}`,
      key: id,
    })
  }

  const fetchById = async () => {
    if (id !== undefined && id !== "") {
      return instance.get<ApiResponse2>(`green-skills/categories/${id}`)
    }

    return { data: null }
  }

  return useQuery(["gs-by-category", id], async () => fetchById(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })
}
