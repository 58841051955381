import React from "react"

const ErrorMessageBuilder = ({
  message,
  includeContactUs,
  includeReportAt,
}: {
  message: string
  includeContactUs?: boolean
  includeReportAt?: boolean
}) => {
  // Helper function to render the 'contact us' link
  const renderContactLink = () => (
    <a
      href="https://support.example.com"
      className="font-semibold text-secondary"
    >
      contact us
    </a>
  )

  // Helper function to render the 'report here' link
  const renderReportLink = () => (
    <a
      href="https://skilledingreen.featurebase.app/"
      className="font-semibold text-secondary"
    >
      here
    </a>
  )

  return (
    <div>
      {message}
      {includeContactUs && includeReportAt ? (
        <>
          For further assistance, you can {renderContactLink()} or report the
          issue {renderReportLink()}.
        </>
      ) : includeContactUs ? (
        <>For further assistance, you can {renderContactLink()}.</>
      ) : includeReportAt ? (
        <>You can report the issue {renderReportLink()}.</>
      ) : null}
    </div>
  )
}

export default ErrorMessageBuilder
