/* eslint-disable no-unused-vars */
import {
  TextContentMetadata,
  VideoMetadata,
} from "../../../skillGalaxy/types/common"

export interface ICommunityUser {
  id: string
  avatar: string
  username: string
  location: string
}

export enum CommunityPostKind {
  ARTICLE = "article",
  COURSE = "course",
  EMPTY = "",
  MEDIA = "media",
  EVENT = "event",
  POLL = "poll",
  OCCASION = "occasion",
  HOW_TO = "how_to",
  MULTIPLE_CHOICE = "multiple_choice",
}

export enum CommunityPostFrom {
  USER = "user",
  SYSTEM = "system",
}

export enum UserFlag {
  EXPERT = "expert",
  INSTRUCTOR = "instructor",
  EMPTY = "",
}

export type SingleOccasion = {
  type: string
  name: string
  description: string
}

export type Occasions = {
  [key: string]: SingleOccasion
}

export type TCoursePostBody = {
  description: string
  id: string
  image: string
  slug: string
  title: string
}

export type TArticlePostBody = {
  description: string
  id: string
  image: string
  name: string
  proficiency: number
  text_content_metadata: TextContentMetadata
  video_content_metadata: VideoMetadata
}

export type TMediaPostBody = { name: string; url: string }[]

export type THowToPostBody = {
  id: string
  name: string
  description: string
  image_url: string
}[]

export type TOccasionPostBody = {
  text: string
  description: string
  image_url: string
}

export type TEventPostBody = {
  type: "online" | "in-person"
  name: string
  timezone: string
  start_date: string | null
  start_time: number | null
  end_date: string | null
  end_time: number | null
  external_event_link: string
  description: string
  speakers: { name: string; image: string }[] | null
}

export interface ICommunityPost {
  id: string
  user_id: string
  user_flag: UserFlag
  from: CommunityPostFrom
  kind: CommunityPostKind
  hashtags: string[]
  body:
    | string
    | TCoursePostBody
    | TArticlePostBody
    | TMediaPostBody
    | TOccasionPostBody
    | TEventPostBody
    | THowToPostBody
  user: ICommunityUser
  total_comments: number
  total_responses: number
  total_views: number
  created_at: string
  updated_at: string | null
  is_liked: boolean
}

export interface ICommunityComment {
  id: string
  user_id: string
  post_id: string
  body: string
  created_at: string
  updated_at: string | null
  user: ICommunityUser
  is_liked: boolean
}

export interface IAddCommunityPost {
  body: string
}

export interface IAddCommunityMedia {
  files?: File[]
}

export interface IAddCommunityEvent {
  event_type: string
  event_name: string
  timezone: string
  event_start_date: string // yyyy-mm-dd
  event_start_time: number // 0000

  external_event_link?: string
  image?: any[]
  event_description?: string
  event_speakers_id?: number[]
  event_end_date?: string // yyyy-mm-dd
  event_end_time?: number // 0000
}

export interface IAddCommunityOccasion {
  occasion_text: string
  image?: any[]
  occasion_kudos_user_id?: string
  occasion_default_image_url?: string
  occasion_description?: string
}

export interface IAddCommunityMultipleChoice {
  multi_choice: {
    choices: {
      is_correct: boolean
      name: string
    }[]
    question: string
  }
}

export interface IAddCommunityHowTos {
  how_to: { description: string; name: string; image_url?: string }[]
}

export interface IAddCommunityPoll {
  poll_question: string
  poll_options: string[]
  poll_duration: "1d" | "3d" | "1w" | "2w"
}

export interface ThisWeeksDiscussion {
  id: string
  user_id: string
  user_flag: "admin" | "user"
  from: string
  kind: "discussion" | string
  hashtags: string[] | null
  body: {
    description: string
    title: string
  }
  total_comments: number
  total_responses: number
  created_at: string
  updated_at: string
  is_liked: boolean
  total_views: number
}

export interface ResponseComment {
  id: string
  user_id: string
  post_id: string
  body: {
    description: string
    title: string
  }
  created_at: string
  updated_at: string
  is_liked: boolean
  total_responses: number
}

export interface Timezone {
  city: string
  timezone: string
  pop: number
}

export type TPostKind =
  | "default"
  | "media"
  | "event"
  | "poll"
  | "occasion"
  | "how_to"
  | "multiple_choice"

export type TPostBody =
  | IAddCommunityPost
  | IAddCommunityEvent
  | IAddCommunityOccasion
  | IAddCommunityMultipleChoice
  | IAddCommunityHowTos
  | IAddCommunityPoll
  | IAddCommunityMedia
