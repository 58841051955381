import React, { useState } from "react"
import {
  TArticlePostBody,
  CommunityPostKind,
  TCoursePostBody,
  ICommunityPost,
  UserFlag,
  CommunityPostFrom,
  TMediaPostBody,
  TOccasionPostBody,
  TEventPostBody,
  THowToPostBody,
} from "../types/commonn"
import { BasicUserInfo } from "../../../../common/components"
import {
  ClockCircleOutlined,
  CommentOutlined,
  LikeOutlined,
  LikeFilled,
  ShareAltOutlined,
  EditOutlined,
  DeleteFilled,
  LoadingOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { ArticlePostBody } from "./ArticlePostBody"
import { CoursePostBody } from "./CoursePostBody"
import { Tag, Popconfirm, Tooltip, Typography, Divider, Empty } from "antd"
import { useCommunityPost } from "../hooks/useCommunityPost"
import clsx from "clsx"
import useInfiniteComments from "../hooks/useInfiniteComments"
import CommentV2 from "./Comment-v2"
import Media from "./post-ui/Media"
import Occasion from "./post-ui/Occasion"
import Event from "./post-ui/Event"
import AddCommentToPost from "./AddCommentToPost"
import HowTo from "./post-ui/HowTo"

dayjs.extend(relativeTime)
interface CommunityPostProps {
  post: ICommunityPost
  onClick?: () => void
  className?: string
  forDetail?: boolean
  observ?: (node: Element | null) => void
}

export const CommunityPost: React.FC<CommunityPostProps> = ({
  post,
  // onClick,
  forDetail,
  className,
  observ,
}) => {
  const {
    liked,
    isUserPost,
    handleLike,
    handleDislike,
    handleDelete,
    // onSubmit,
    // updatePost,
  } = useCommunityPost(post)
  const [showComments, setShowComments] = useState(false)

  const { comments, lastCardElementRef, commentsQuery } = useInfiniteComments(
    post?.id ?? "",
    showComments,
  )

  return (
    <div
      ref={observ}
      id={"#" + post.id}
      className={clsx(
        {
          "hover:shadow-lg duration-300 transition-all basic-card shadow-none ring-1 ring-gray-300 cursor-pointer w-full":
            !forDetail,
        },
        className,
      )}
      // onClick={() => {
      //   onClick?.()
      // }}
    >
      {(post?.user_flag === UserFlag.EXPERT ||
        post?.user_flag === UserFlag.INSTRUCTOR) && (
        <Tag
          className={`capitalize w-fit mb-3 text-white-custom border-none block md:hidden ${
            post?.user_flag === UserFlag.EXPERT ? "bg-secondary" : "bg-danger"
          }`}
        >
          {post?.user_flag}
        </Tag>
      )}
      <div className="flex flex-wrap justify-between gap-3">
        <div className="flex items-start gap-2">
          <BasicUserInfo
            avatar={post?.user?.avatar}
            location={post?.user?.location}
            name={post?.user?.username}
          />
          {(post?.user_flag === UserFlag.EXPERT ||
            post?.user_flag === UserFlag.INSTRUCTOR) && (
            <Tag
              className={`capitalize text-white-custom border-none hidden md:block ${
                post?.user_flag === UserFlag.EXPERT
                  ? "bg-secondary"
                  : "bg-danger"
              }`}
            >
              {post?.user_flag}
            </Tag>
          )}
          {post?.updated_at !== null && (
            <div className="text-sm text-gray-300"> [Edited]</div>
          )}
        </div>
        <div className="flex items-center gap-2">
          <ClockCircleOutlined />
          <span>{dayjs(post?.created_at)?.fromNow(true)}</span>
        </div>
      </div>

      <div className="flex flex-wrap items-center gap-2 mt-4">
        {post?.hashtags?.map((hashtag, index) => (
          <span key={index} className="font-semibold ">
            {hashtag}
          </span>
        ))}
      </div>
      <CommunityPostBody post={post} />

      <div className="flex items-center gap-4 mt-4 ">
        {isUserPost && post?.from === CommunityPostFrom.USER && (
          <EditOutlined
            // onClick={() => setEditMode(true)}
            className="transition-all duration-200 cursor-pointer hover:text-primary hover:scale-110"
          />
        )}
        <div className="flex items-center gap-2">
          {liked?.liked ? (
            <LikeFilled
              className="cursor-pointer hover:text-danger"
              onClick={(e) => {
                e.stopPropagation()
                handleDislike()
              }}
            />
          ) : (
            <LikeOutlined
              onClick={(e) => {
                e.stopPropagation()
                handleLike()
              }}
              className="transition-all duration-200 cursor-pointer hover:text-primary hover:scale-110"
            />
          )}
          <span>{liked?.likesCount}</span>
        </div>
        <div
          className="flex items-center gap-2"
          onClick={() => setShowComments(!showComments)}
        >
          <CommentOutlined />
          <span>{post?.total_comments}</span>
        </div>
        <div className="flex items-center gap-2">
          <ShareAltOutlined />
        </div>
        {isUserPost && (
          <Popconfirm
            title="Are you sure to delete this post?"
            onConfirm={handleDelete}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled className="transition-all duration-200 cursor-pointer hover:text-danger hover:scale-110" />
          </Popconfirm>
        )}
        {post?.total_views > 0 && (
          <div className="ml-auto">
            <Tooltip title="Views">
              <span className="">🔥</span>
              <span>{post?.total_views}</span>
            </Tooltip>
          </div>
        )}
      </div>
      {showComments &&
        (commentsQuery?.isLoading ? (
          <>
            <Divider />
            <div className="grid p-6 place-content-center">
              <LoadingOutlined className="text-base" />
            </div>
          </>
        ) : (
          <>
            <Divider />
            <div className="mt-6 space-y-6">
              <AddCommentToPost postId={post.id} />
              {comments?.length > 0 ? (
                comments?.map((comment, idx) => (
                  <CommentV2
                    observ={lastCardElementRef}
                    key={idx}
                    comment={comment}
                  />
                ))
              ) : (
                <>
                  <Empty description="Be the first to comment" />
                </>
              )}
            </div>
          </>
        ))}
    </div>
  )
}

export const CommunityPostBody: React.FC<{ post: ICommunityPost }> = ({
  post,
}) => {
  switch (post?.kind) {
    case CommunityPostKind.EMPTY:
      return (
        <Typography.Paragraph
          ellipsis={{ rows: 6, expandable: true, symbol: "more" }}
        >
          <div
            // className="mt-4"
            dangerouslySetInnerHTML={{
              __html: (post?.body as string)
                ?.replace(/#(\w+)/g, "<strong>#$1</strong>")
                .replace(/\n/g, "<br />"),
            }}
          />
        </Typography.Paragraph>
      )
    case CommunityPostKind.MEDIA:
      return <Media body={post?.body as TMediaPostBody} />
    case CommunityPostKind.OCCASION:
      return <Occasion body={post?.body as TOccasionPostBody} />
    case CommunityPostKind.EVENT:
      return <Event body={post?.body as TEventPostBody} />
    case CommunityPostKind.HOW_TO:
      return <HowTo body={post?.body as THowToPostBody} />
    case CommunityPostKind.COURSE:
      return <ArticlePostBody article={post?.body as TArticlePostBody} />
    case CommunityPostKind.ARTICLE:
      return <CoursePostBody course={post?.body as TCoursePostBody} />
  }
}
