import React, { useState } from "react"
import { useForm,  useFieldArray } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "react-query"
import { message } from "antd"
import {
  HowToFormData,
  howToSchema,
} from "../components/new-post-forms/schemas"
import { IAddCommunityHowTos, TPostBody, TPostKind } from "../types/commonn"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import { useNewPostStore } from "../../store/newPostStore"
import { createCommunityPost } from "../apis/feed-post"


const convertToHowTos = (data: HowToFormData): IAddCommunityHowTos => {
  const { howTos } = data

  const how_to = howTos.map((howTo) => ({
    description: howTo.description,
    name: howTo.name,
    image_url: howTo.image_url,
  }))

  return { how_to }
}

export const useHowToForm = () => {
  const { closeModal } = useNewPostStore()
  const { handleSubmit, control, formState } = useForm<HowToFormData>({
    resolver: zodResolver(howToSchema),
    defaultValues: {
      howTos: [{ id: 1, name: "", description: "", image_url: undefined }],
    },
  })
  const [isHovered, setIsHovered] = useState(false)

  const { fields, append, remove } = useFieldArray({
    control,
    name: "howTos" as const,
  })

  const createPost = useMutation(
    ({ post, kind }: { post: TPostBody; kind?: TPostKind }) =>
      createCommunityPost(post, kind),
  )
  const queryClient = useQueryClient()

  const onSubmit = async (data: HowToFormData) => {
    console.log(convertToHowTos(data))
    message.loading({
      content: "Creating how-to post...",
      key: "create-post",
    })

    await createPost.mutateAsync(
      {
        post: convertToHowTos(data),
        kind: "how_to",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("community-post-list")
          message.success({
            content: "How to Post created successfully!",
            key: "create-post",
          })
          closeModal()
        },
        onError: (error: any) => {
          message.open({
            key: "create-event-post",
            duration: 5,
            content: React.createElement(ErrorMessageBuilder, {
              message: `We encountered an issue while attempting to create your how-tos post. If the problem continues, please refresh the page. Error ${error.response?.data?.message}`,
              includeReportAt: true,
            }),
            type: "error",
          })
        },
      },
    )
  }

  return {
    handleSubmit,
    control,
    formState,
    fields,
    append,
    remove,
    isHovered,
    setIsHovered,
    createPost,
    closeModal,
    onSubmit,
  }
}
