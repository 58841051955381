import { useFetchDetail, useFetchList } from "../../../../hooks/generic_rest"

import {
  CountriesReferenceResponse,
  SolutionReferenceResponse,
  StageReferenceResponse,
} from "../types/response"
import { FOUNDER_REFERENCE_ENDPOINTS } from "../apis/endpoint"

const LIST_LOCATION_REF_FOUNDER_QUERY_NAME = "list-location-ref-founder"
const LIST_STAGE_REF_FOUNDER_QUERY_NAME = "list-stage-ref-founder"
const LIST_SOLUTION_REF_FOUNDER_QUERY_NAME = "list-solution-ref-founder"
export const useFounderReference = () => {
  const {
    data: listStage,
    isLoading: listLoadingStage,
    isError: listErrorStage,
  } = useFetchDetail<StageReferenceResponse>(
    `${FOUNDER_REFERENCE_ENDPOINTS.STAGE}`,
    {
      queryName: LIST_SOLUTION_REF_FOUNDER_QUERY_NAME,
    },
  )
  const {
    data: listSolutions,
    isLoading: listLoadingSolutions,
    isError: listErrorSolutions,
  } = useFetchDetail<SolutionReferenceResponse>(
    `${FOUNDER_REFERENCE_ENDPOINTS.SOLUTION}`,
    {
      queryName: LIST_STAGE_REF_FOUNDER_QUERY_NAME,
    },
  )
  const queryParams = {
    page: 1,
    limit: 300,
    // name: search,
  }
  const {
    data: listLocations,
    isLoading: listLoadingLocations,
    isError: listErrorLocations,
  } = useFetchList<CountriesReferenceResponse>(
    `${FOUNDER_REFERENCE_ENDPOINTS.LOCATION}`,
    {
      queryParams,
      queryName: LIST_LOCATION_REF_FOUNDER_QUERY_NAME,
    },
  )

  return {
    stageData: {
      listStage: listStage,
      listLoadingStage,
      listErrorStage,
    },
    solutionData: {
      listSolutions: listSolutions,
      listLoadingSolutions,
      listErrorSolutions,
    },
    locationData: {
      listLocations: listLocations,
      listLoadingLocations,
      listErrorLocations,
    },
  }
}
