/* eslint-disable */

import React from "react"

interface BasicContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children: React.ReactNode | React.ReactElement
  type?: "small" | "default" | "large"
}

export const BasicContainer = React.forwardRef<
  HTMLDivElement,
  BasicContainerProps
>(({ className = "", children, type = "default", ...rest }, ref) => {
  const containerStyles = {
    small: "py-[30px] px-[17px]",
    default: "py-5 px-6",
    large: "",
  }

  return (
    <div
      ref={ref}
      className={`rounded-[10px] shadow-custom bg-white-custom ${containerStyles[type]} ${className}`}
      {...rest}
    >
      {children}
    </div>
  )
})
