import React from "react"
import { Statistic } from "antd"
import {
  CommentOutlined,
  UserOutlined,
  SnippetsOutlined,
  RiseOutlined,
} from "@ant-design/icons"
import clsx from "clsx"

const MyAnalytics = () => {
  const data = [
    {
      title: "Followers",
      count: 1000,
      // change: 200,
      // positive: true,
      icon: <UserOutlined />,
      extraClassName: "",
    },
    {
      title: "Posts",
      count: 500,
      // change: -50,
      // positive: false,
      icon: <SnippetsOutlined />,
      extraClassName: "",
    },
    {
      title: "Impressions",
      count: 500,
      // change: -50,
      // positive: false,
      icon: <RiseOutlined />,
      extraClassName: "",
    },
    {
      title: "Comments",
      count: 2000,
      // change: 300,
      // positive: true,
      icon: <CommentOutlined />,
      extraClassName: "",
    },
  ]

  return (
    <>
      <div className="text-base mt-5 my-2">
        Your analytics dashboard showing you statistics about your account. (May
        take some time to update.)
      </div>
      <div className="grid grid-cols-2 gap-8 p-4">
        {data.map((item, index) => (
          <div
            key={index}
            className={clsx(
              "text-center rounded-lg col-span-1 ring-1 ring-backdrop p-10 bg-white shadow-md",
              item.extraClassName,
            )}
          >
            <Statistic
              title={item.title}
              value={item.count}
              precision={0}
              // valueStyle={{ color: item.positive ? "#3f8600" : "#cf1322" }}
              prefix={item.icon}
              // suffix={`(${item.change})`}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default MyAnalytics
