import React, { useContext } from "react"
import { PageContent } from "../../../../common/layout"
import { DefaultSkillImage } from "../../../../assets/images"
import { AccountContext } from "../../../../common/layout/PageLayout"
import UserProfilePicture from "../../../../common/components/UserProfilePicture"
import { EditOutlined, ArrowLeftOutlined } from "@ant-design/icons"
import { Tabs } from "antd"
import MyPostsList from "../components/MyPostsList"
import MyAnalytics from "../components/MyAnalytics"
import { Link } from "react-router-dom"
import { CommunityRightSidebar } from "../components/CommunityRightSidebar"

const MyCommunityProfile = () => {
  return (
    <PageContent
      header={null}
      content={<Content />}
      hideRightSidebarOnMobile
      contentRightSidebar={<CommunityRightSidebar />}
    />
  )
}

const Content = () => {
  const account = useContext(AccountContext)
  const accountData = account?.data?.data

  return (
    <>
      <section className="relative p-5 bg-white rounded-xl">
        <img
          className="absolute top-0 left-0 w-full h-[300px] object-cover rounded-t-xl z-0"
          src={DefaultSkillImage}
        />
        <UserProfilePicture
          size={140}
          className="absolute top-[190px] left-[10px] z-10 shadow cursor-none"
        />
        <div className="absolute z-10 p-4 transition-all duration-300 cursor-pointer hover:shadow-xl right-1 top-1 hover:bg-white rounded-xl">
          <EditOutlined className="text-xl text-backdrop" />
        </div>

        <div className="mt-[320px] relative">
          <div className="absolute z-10 p-4 transition-all duration-300 cursor-pointer hover:shadow-xl right-1 top-1 hover:bg-white rounded-xl">
            <Link to={"/dashboard/launchpad/cv-builder"}>
              <EditOutlined className="text-xl text-backdrop" />
            </Link>
          </div>

          <h1 className="text-3xl font-semibold tracking-tight">
            {accountData?.first_name} {accountData?.last_name}
          </h1>
          <div
            className="-mt-4 -mb-3 text-lg tracking-wide"
            dangerouslySetInnerHTML={{ __html: accountData?.about_me ?? "" }}
          />
        </div>
        <div className="flex items-center justify-between">
          <div className="text-slate-400">{accountData?.country}</div>

          <Link
            to={"/dashboard/launchpad/cv-builder"}
            className="px-4 py-2 font-medium text-white transition-all duration-300 bg-primary hover:text-white hover:scale-105"
          >
            View Green CV
          </Link>
        </div>
      </section>

      <section className="basic-card bg-white-custom">
        <Link
          to={"/community/feed"}
          className="flex items-center gap-2 text-primary"
        >
          <ArrowLeftOutlined />
          <span>back to feed</span>
        </Link>
        <Tabs className="mt-2 custom-tabs" items={tabsData} />
      </section>
    </>
  )
}

const tabsData = [
  {
    key: "posts",
    label: "Posts",
    children: <MyPostsList />,
  },
  {
    key: "analytics",
    label: "Analytics",
    children: <MyAnalytics />,
  },
]

export default MyCommunityProfile
