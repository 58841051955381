import React, { useContext } from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../../common/layout"
import ListFounderContent from "../components/ListFounderContent"
import { Button } from "antd"
import { useProfile } from "../../../missionControl/hooks/useProfile"
import { useModalStore } from "../../../../stores"
import { useListFounder } from "../hooks/useFounder"
import { useNavigate } from "react-router-dom"
import {
  ITourContext,
  TourContext,
} from "../../../../common/components/tour/TourProvider"

interface ListFounderProps {}

const ListFounder: React.FC<ListFounderProps> = () => {
  const { account } = useProfile()
  const { founderData, queryFounder, referenceData } = useListFounder()
  const openModal = useModalStore((state) => state.openModal)

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/community/member",
      title: "Community",
    },
    {
      title: "Founders",
    },
  ]

  const navigate = useNavigate()

  const {
    refs: { refIAmAFounder },
  } = useContext(TourContext) as ITourContext

  return (
    <PageContent
      header={
        <PageHeader
          breadcrumbItems={items}
          title={"Sustainability Changemakers | Startup Founders"}
          needButton={account ? true : false}
          buttonComponent={
            account && (
              <Button
                type={
                  queryFounder?.statusFounder?.is_founder
                    ? "default"
                    : "primary"
                }
                onClick={(e) => {
                  e.stopPropagation()
                  if (account) {
                    if (queryFounder?.statusFounder?.is_founder) {
                      navigate(
                        `/community/founder/profile/${account?.data?.data?.id}`,
                      )
                    } else {
                      queryFounder?.handleToBeFounder()
                    }
                  } else {
                    openModal()
                  }
                }}
                className="rounded border-primary"
              >
                {queryFounder?.statusFounder?.is_founder
                  ? "My Founder Profile"
                  : "I am a Founder"}
              </Button>
            )
          }
        />
      }
      content={
        <ListFounderContent
          founderData={founderData}
          queryFounder={queryFounder}
          referenceData={referenceData}
        />
      }
    />
  )
}

export default ListFounder
