import React from "react"
import { ICommunityPost } from "../types/commonn"
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons"
import { CommunityPost } from "./CommunityPost"
import { postCommentList } from "../apis/feed-comment"
import { useQuery } from "react-query"
import { List, message } from "antd"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import AddCommentToPost from "./AddCommentToPost"

interface CommunityPostDetailProps {
  post: ICommunityPost
  removePostShowDetails: () => void
}

export const CommunityPostDetail: React.FC<CommunityPostDetailProps> = ({
  post,
  removePostShowDetails,
}) => {
  const [limit, setLimit] = React.useState(10)
  const [page, setPage] = React.useState(1)
  const commentsQuery = useQuery(
    ["post-comment-list", post.id, limit, page],
    () => postCommentList(post.id, limit, page),
    {
      onError: () => {
        message.open({
          key: "post-comment-list",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch post comments. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )
  return (
    <div>
      <button
        className="flex items-center gap-2 p-2 text-white transition-all duration-300 border-none rounded cursor-pointer hover:text-primary hover:bg-primary-bg text-md bg-primary"
        onClick={removePostShowDetails}
      >
        <ArrowLeftOutlined className="" />
        <span>back</span>
      </button>
      <CommunityPost
        forDetail
        className="flex-grow mt-4 bg-white basic-card"
        post={post}
      />
      <div className="flex flex-col">
        {commentsQuery?.isLoading ? (
          <div
            className="flex items-center justify-center"
            style={{ height: "300px" }}
          >
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>
        ) : (
          <div className="flex flex-col gap-4 mt-4 bg-white basic-card">
            {commentsQuery?.data?.data?.data?.list &&
              commentsQuery?.data?.data?.data?.list?.length > 0 && (
                <List
                  dataSource={commentsQuery?.data?.data?.data?.list ?? []}
                  loading={commentsQuery?.isLoading}
                  pagination={
                    commentsQuery?.data?.data?.data?.total_data > 10
                      ? {
                          pageSize: limit,
                          total:
                            commentsQuery?.data?.data?.data?.total_data ?? 0,
                          onChange: (page) => {
                            setPage(page)
                          },
                          showSizeChanger: true,
                          onShowSizeChange: (current, size) => {
                            setLimit(size)
                          },
                        }
                      : undefined
                  }
                  header={`${
                    commentsQuery?.data?.data?.data?.list?.length ?? "No"
                  } ${
                    commentsQuery?.data?.data?.data?.list &&
                    commentsQuery?.data?.data?.data?.list?.length > 1
                      ? "replies"
                      : "reply"
                  }`}
                  itemLayout="horizontal"
                  renderItem={() => <></>}
                />
              )}
            <AddCommentToPost postId={post.id} />
          </div>
        )}
      </div>
    </div>
  )
}
