import { Controller } from "react-hook-form"
import { Input, Button, Form as AntForm, Divider, Upload } from "antd"
import {
  SendOutlined,
  DeleteOutlined,
  PlusOutlined,
  PictureOutlined,
} from "@ant-design/icons"
import NewPostIcons from "../NewPostIcons"
import { deleteMedia, uploadMedia } from "../../apis/feed-post"
import clsx from "clsx"
import { beforeUpload } from "../../../../../common/components/FormImageUploader"
import { RcFile } from "antd/es/upload"
import { useHowToForm } from "../../hooks/useHowToForm"
import React from "react"

const MAX_HOW_TOS = 10

const HowTo = () => {
  const {
    handleSubmit,
    control,
    formState,
    fields,
    append,
    remove,
    isHovered,
    setIsHovered,
    createPost,
    closeModal,
    onSubmit,
  } = useHowToForm()

  return (
    <AntForm onFinish={handleSubmit(onSubmit)} className="relative my-6">
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={clsx("", {
            "mt-6": index > 0,
            "mb-6": index === MAX_HOW_TOS - 1,
          })}
        >
          <h3 className="text-2xl font-semibold">#{index + 1}</h3>
          <div className="flex w-full gap-4 mt-2">
            <Controller
              name={`howTos.${index}.image_url` as const}
              control={control}
              defaultValue={undefined}
              render={({ field: { onChange, value } }) => {
                return value ? (
                  <div
                    className={clsx("relative w-1/5 aspect-square rounded-lg")}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{ borderRadius: "20px" }}
                  >
                    <img
                      src={value}
                      alt="avatar"
                      className="absolute top-0 left-0 object-contain w-full h-full"
                      style={{ borderRadius: "20px" }}
                    />
                    <div
                      className={clsx(
                        "absolute inset-0 top-0 left-0 z-10 flex items-center justify-center w-full h-full gap-2 bg-black opacity-0 transtion-all duration-300",
                        { "hover:opacity-50 ": isHovered },
                      )}
                      style={{ borderRadius: "20px" }}
                    >
                      <DeleteOutlined
                        className="z-50 text-2xl text-white"
                        onClick={async (e) => {
                          e.stopPropagation()
                          await deleteMedia(value)
                          onChange(undefined)
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <Upload.Dragger
                    name="image"
                    listType="picture"
                    className="w-1/5 aspect-square rounded-lg"
                    style={{ borderRadius: "20px" }}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    customRequest={async ({ file }) => {
                      const response = await uploadMedia(file as RcFile)
                      onChange(response.data?.data)
                    }}
                    onRemove={async () => {
                      await deleteMedia(value ?? "")
                      onChange(undefined)
                    }}
                    maxCount={1}
                  >
                    <>
                      <PictureOutlined className="text-3xl" />
                      <p className="mt-1">{"Upload"}</p>
                    </>
                  </Upload.Dragger>
                )
              }}
            />

            <div className="flex flex-col flex-grow gap-y-3">
              <div className="flex items-center gap-2">
                <Controller
                  name={`howTos.${index}.name` as const}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Name"
                      status={
                        formState?.errors?.howTos?.[index]?.name
                          ? "error"
                          : undefined
                      }
                    />
                  )}
                />
                {index !== 0 && (
                  <Button danger onClick={() => remove(index)}>
                    <DeleteOutlined />
                  </Button>
                )}
              </div>
              {formState?.errors?.howTos?.[index]?.name && (
                <div className="text-danger -my-2">
                  {formState?.errors?.howTos?.[index]?.name?.message}
                </div>
              )}
              <Controller
                name={`howTos.${index}.description` as const}
                control={control}
                render={({ field }) => (
                  <Input.TextArea
                    {...field}
                    placeholder="Description"
                    className="flex-grow"
                    status={
                      formState?.errors?.howTos?.[index]?.description
                        ? "error"
                        : undefined
                    }
                  />
                )}
              />
              {formState?.errors?.howTos?.[index]?.description && (
                <div className="text-danger -my-2">
                  {formState?.errors?.howTos?.[index]?.description?.message}
                </div>
              )}
            </div>
          </div>
          {index !== fields.length - 1 && <Divider />}
        </div>
      ))}
      {fields.length < MAX_HOW_TOS && (
        <div className="flex justify-center w-full my-4">
          <button
            className="p-4 mx-auto text-white rounded-full cursor-pointer bg-primary"
            onClick={() =>
              append({
                id: fields.length + 1,
                name: "",
                description: "",
                image_url: undefined,
              })
            }
          >
            <PlusOutlined className="text-xl" />
          </button>
        </div>
      )}
      <div className="flex items-center justify-between w-full">
        <NewPostIcons />
        <div className="flex items-center gap-4">
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            className="text-white bg-primary"
            loading={createPost.isLoading}
            icon={<SendOutlined />}
            disabled={createPost.isLoading || !formState.isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Post
          </Button>
        </div>
      </div>
    </AntForm>
  )
}

export default HowTo
