import { Button, Modal } from "antd"

import React from "react"

import {
  storeModalSchedule,
  useMentorScheduleStore,
  useStoreMySchedule,
} from "../stores/storeMentorSchedule"
import CalendarAvailabilty from "./CalenderAvailability"
import {
  useListMentorSchedule,
  useListMyBookings,
} from "../hooks/useMentorSchedule"
import { useParams } from "react-router-dom"

interface ModalScheduleProps {}

const ModalSchedule: React.FC<ModalScheduleProps> = () => {
  const { mentorId, from, timeReschedule } = useStoreMySchedule()

  const { id } = useParams()
  const { isOpen, openModal, closeModal } = storeModalSchedule()
  const { selectedTimeSlot } = useMentorScheduleStore()

  const { handleSubmitRequest, isLoadingSaveRequest } = useListMentorSchedule(
    from === "reschedule" ? mentorId! : id!,
  )

  const { handleSubmitRescheduleBooking, loadingRescheduleBooking } =
    useListMyBookings()

  return (
    <Modal
      title={`${
        from === "reschedule" ? "Reschedule" : "Make"
      } Appointment Schedule`}
      width={800}
      footer={
        <div className="flex items-center justify-end gap-x-3">
          <Button type="default" onClick={() => closeModal()}>
            Cancel
          </Button>
          {from === "reschedule" ? (
            <Button
              type="primary"
              disabled={!timeReschedule}
              loading={loadingRescheduleBooking}
              onClick={() => {
                handleSubmitRescheduleBooking()
                closeModal()
              }}
            >
              {from === "reschedule" ? "Reschedule" : "Make"} Appointment
            </Button>
          ) : (
            <Button
              type="primary"
              disabled={!selectedTimeSlot}
              loading={isLoadingSaveRequest}
              onClick={() => {
                handleSubmitRequest()
              }}
            >
              {from === "reschedule" ? "Reschedule" : "Make"} Appointment
            </Button>
          )}
        </div>
      }
      open={isOpen}
      onOk={() => openModal()}
      onCancel={() => closeModal()}
    >
      <CalendarAvailabilty />
    </Modal>
  )
}

export default ModalSchedule
